import React from 'react'
import { Link } from 'gatsby'
import { ArrowRight } from 'react-feather'
import './PostGrid.css'

class PostList extends React.Component {
  static defaultProps = {
    posts: [],
    title: '',
    limit: 6,
    showLoadMore: true,
    loadMoreTitle: 'View More Articles',
    perPageLimit: 6
  }

  state = {
    limit: this.props.limit
  }

  render() {
    const { posts, title, showLoadMore, loadMoreTitle } = this.props,
      { limit } = this.state,
      visiblePosts = posts.slice(0, limit || posts.length)

    return (
      <div className="Content">
        {title && <h2 className="PostGrid--Title">{title}</h2>}
        {!!visiblePosts.length && (
          <div className="Home--Articles--Grid">
            {visiblePosts.map((post, index) => (
              <div key={post + '-' + index}>
                <div className="Home--Articles--Content">
                  {/* <div className="PostCard--Category">
                    {post.categories &&
                      post.categories.map(cat => cat.category).join(', ')}
                  </div> */}
                  {post.title && (
                    <h4 className="Home--Articles--Title">
                      <Link to={post.slug} className={`PostArticle`}>
                        {post.title}
                      </Link>
                    </h4>
                  )}

                  {post.excerpt && (
                    <div className="Home--Articles--Excerpt">
                      {post.excerpt}
                    </div>
                  )}
                </div>
              </div>
              //<PostCard key={post.title + index} {...post} featuredImage={false} />
            ))}
          </div>
        )}
        {showLoadMore && visiblePosts.length < posts.length && (
          <div className="section">
            <Link to="/articles" className={`PostArticle`}>
              {loadMoreTitle} <ArrowRight size={16} />
            </Link>
          </div>
        )}
      </div>
    )
  }
}

export default PostList
