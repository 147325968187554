import React from 'react'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import qs from 'qs'

import Content from '../components/Content'
import PostList from '../components/PostList'
import ProjectGrid from '../components/ProjectGrid'
import Layout from '../components/Layout'

/**
 * Filter posts by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {posts} object
 */
export const byDate = (posts) => {
  const now = Date.now()
  return posts.filter((post) => Date.parse(post.date) <= now)
}

/**
 * filter posts by category.
 *
 * @param {posts} object
 * @param {title} string
 * @param {contentType} string
 */
export const byCategory = (posts, title, contentType) => {
  const isCategory = contentType === 'postCategories'
  const byCategory = (post) =>
    post.categories &&
    post.categories.filter((cat) => cat.category === title).length
  return isCategory ? posts.filter(byCategory) : posts
}

// Export Template for use in CMS preview
export const HomePageArticlesTemplate = ({
  title,
  subtitle,
  body,
  featuredImage,
  posts = [],
  postCategories = [],
  projects = [],
  enableSearch = true,
  contentType
}) => (
  <main className="main-body">
    <div className="container">
      <div className="row">
        <div className="col-6">
          <h2>{title}</h2>
          <h3>{subtitle}</h3>
          <Content source={body} />
        </div>
        <div className="col-6">
          <img
            className="Home--Profile"
            src={featuredImage}
            alt="Daniel Kim with aurora in the background"
            title="Daniel Kim"
          />
        </div>
      </div>
      <Location>
        {({ location }) => {
          let filteredPosts =
            posts && !!posts.length
              ? byCategory(byDate(posts), title, contentType)
              : []

          let filteredProjects =
            projects && !!projects.length
              ? byCategory(byDate(projects), title, contentType)
              : []

          let queryObj = location.search.replace('?', '')
          queryObj = qs.parse(queryObj)

          if (enableSearch && queryObj.s) {
            const searchTerm = queryObj.s.toLowerCase()
            filteredPosts = filteredPosts.filter((post) =>
              post.frontmatter.title.toLowerCase().includes(searchTerm)
            )
          }

          if (enableSearch && queryObj.s) {
            const searchTerm = queryObj.s.toLowerCase()
            filteredProjects = filteredProjects.filter((project) =>
              project.frontmatter.title.toLowerCase().includes(searchTerm)
            )
          }

          return (
            <div className="Home--Section">
              <h2>Latest articles</h2>

              {!!posts.length && (
                <section>
                  <div>
                    <PostList posts={filteredPosts} />
                  </div>
                </section>
              )}

              {!!projects.length && (
                <section className="section">
                  <div className="">
                    <ProjectGrid
                      projects={filteredProjects}
                      limit={4}
                      minimalDesign={true}
                      loadMoreTitle={'View More Projects'}
                    />
                  </div>
                </section>
              )}
            </div>
          )
        }}
      </Location>
    </div>
  </main>
)

// Export Default HomePageArticles for front-end
const HomePageArticles = ({
  data: { page, posts, postCategories, projects, projectCategories }
}) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <HomePageArticlesTemplate
      {...page}
      {...page.fields}
      {...page.frontmatter}
      body={page.html}
      posts={posts.edges.map((post) => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
      postCategories={postCategories.edges.map((post) => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
      projects={projects.edges.map((project) => ({
        ...project.node,
        ...project.node.frontmatter,
        ...project.node.fields
      }))}
      projectCategories={projectCategories.edges.map((project) => ({
        ...project.node,
        ...project.node.frontmatter,
        ...project.node.fields
      }))}
    />
  </Layout>
)

export default HomePageArticles

export const pageQuery = graphql`
  ## Query for HomePageArticles data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePageArticles($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      fields {
        contentType
      }
      frontmatter {
        title
        excerpt
        template
        subtitle
        featuredImage
      }
    }

    posts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            categories {
              category
            }
            featuredImage
          }
        }
      }
    }
    postCategories: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "postCategories" } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    projects: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "projects" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            date
            categories {
              category
            }
            featuredImage
          }
        }
      }
    }
    projectCategories: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "projectCategories" } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
